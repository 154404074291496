<template>
  <div class="total drag" v-draw id="RqSoftMeasurement">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="zhuangshizb"></div>
        <div class="zhuangshizj">{{projectData.project}}_软测量模型</div>
        <div class="zhuangshiyb" @click="closeCompon">
          <div class="icon"></div>
        </div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">煤气软测量模型</div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- 上面的框框 -->
      <div class="up flex">
        <!-- 左边一列 -->
        <div class="up_left_line">
          <div class="left_line1">煤<br />气<br />软<br />测<br />量</div>
        </div>
        <!-- 右边部分 -->
        <div class="right_line">
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_2f">归零阀位</div>
              <div
                class="spsecial_num color_00"
                @click="
                  toIpt(infoList.MCRCL1.FV_0, '归零阀位', 'FV_0', 'MCRCL1')
                "
              >
                {{ infoList.MCRCL1.FV_0 }}
              </div>
            </div>
            <div class="right_column2 color_2f">高炉煤气</div>
            <div class="right_column2 color_2f">高炉煤气</div>
            <div class="right_column2 color_2f">高炉煤气</div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">压力采样周期</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(infoList.MCRCL1.CYZQ, '压力采样周期', 'CYZQ', 'MCRCL1')
              "
            >
              {{ infoList.MCRCL1.CYZQ }}
            </div>
            <div class="right_column2"></div>
            <div class="right_column2"></div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气压力</div>
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.PICA_104_B,
                  '煤气压力',
                  'PICA_104_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.PICA_104_B }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.PICA_105_B,
                  '煤气压力',
                  'PICA_105_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.PICA_105_B }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.PICA_106_B,
                  '煤气压力',
                  'PICA_106_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.PICA_106_B }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气压力一般工作点</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1.P01SP,
                  '煤气压力一般工作点',
                  'P01SP',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.P01SP }}
            </div>
            <div class="right_column2"></div>
            <div class="right_column2"></div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气阀开度系数</div>
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL1.KFM01_B1,
                  '煤气阀开度系数',
                  'KFM01_B1',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.KFM01_B1 }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL2.KFM01_B2,
                  '煤气阀开度系数',
                  'KFM01_B2',
                  'MCRCL2'
                )
              "
            >
              {{ infoList.MCRCL2.KFM01_B2 }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL3.KFM01_B3,
                  '煤气阀开度系数',
                  'KFM01_B3',
                  'MCRCL3'
                )
              "
            >
              {{ infoList.MCRCL3.KFM01_B3 }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气压力系数</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1.KM01_B1,
                  '煤气压力系数',
                  'KM01_B1',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.KM01_B1 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL2.KM01_B2,
                  '煤气压力系数',
                  'KM01_B2',
                  'MCRCL2'
                )
              "
            >
              {{ infoList.MCRCL2.KM01_B2 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL3.KM01_B3,
                  '煤气压力系数',
                  'KM01_B3',
                  'MCRCL3'
                )
              "
            >
              {{ infoList.MCRCL3.KM01_B3 }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气阀门开度</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1.KM01_B1,
                  '煤气阀门开度',
                  'KM01_B1',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.KM01_B1 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCSOUREC.KF_106_B,
                  '煤气阀门开度',
                  'KF_106_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.KF_106_B }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCSOUREC.KF_110_B,
                  '煤气阀门开度',
                  'KF_110_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.KF_110_B }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气流量偏置</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1.PZMQ1_B1,
                  '煤气流量偏置',
                  'PZMQ1_B1',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.PZMQ1_B1 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL2.PZMQ1_B2,
                  '煤气流量偏置',
                  'PZMQ1_B2',
                  'MCRCL2'
                )
              "
            >
              {{ infoList.MCRCL2.PZMQ1_B2 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL3.PZMQ1_B3,
                  '煤气流量偏置',
                  'PZMQ1_B3',
                  'MCRCL3'
                )
              "
            >
              {{ infoList.MCRCL3.PZMQ1_B3 }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气流量滤波时间</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1.TCMQ,
                  '煤气流量滤波时间',
                  'TCMQ',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.TCMQ }}
            </div>
            <div class="right_column2"></div>
            <div class="right_column2"></div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气流量软测量值</div>
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL1.FM01_B1,
                  '煤气流量软测量值',
                  'FM01_B1',
                  'MCRCL1'
                )
              "
            >
              {{ infoList.MCRCL1.FM01_B1 }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL2.FM01_B2,
                  '煤气流量软测量值',
                  'FM01_B2',
                  'MCRCL2'
                )
              "
            >
              {{ infoList.MCRCL2.FM01_B2 }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCRCL3.FM01_B3,
                  '煤气流量软测量值',
                  'FM01_B3',
                  'MCRCL3'
                )
              "
            >
              {{ infoList.MCRCL3.FM01_B3 }}
            </div>
          </div>
          <div class="up_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_8a">煤气流量实际值</div>
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.FICA_105_B,
                  '煤气流量实际值',
                  'FICA_105_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.FICA_105_B }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.FICA_106_B,
                  '煤气流量实际值',
                  'FICA_106_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.FICA_106_B }}
            </div>
            <div
              class="right_column2 color_2f"
              @click="
                toIpt(
                  infoList.MCSOUREC.FICA_107_B,
                  '煤气流量实际值',
                  'FICA_107_B',
                  'MCSOUREC'
                )
              "
            >
              {{ infoList.MCSOUREC.FICA_107_B }}
            </div>
          </div>
        </div>
      </div>
      <!-- 下面的框框 -->
      <div class="down flex">
        <!-- 左边一列 -->
        <div class="down_left_line">
          <div class="left_line2">
            伯<br />努<br />利<br />煤<br />气<br />软<br />测<br />量
          </div>
        </div>
        <!-- 右边部分 -->
        <div class="right_line">
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_word1">参数名称</div>
            </div>
            <div class="right_column2">一层高炉煤气</div>
            <div class="right_column2">二层高炉煤气</div>
            <div class="right_column2">三层高炉煤气</div>
          </div>
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_00e">煤气管道直径DN</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL1.DN,
                  '煤气管道直径DN',
                  'DN',
                  'MCRCL1__p__GLMQRCL1'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL1.DN }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.DN,
                  '煤气管道直径DN',
                  'DN',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.DN }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL3.DN,
                  '煤气管道直径DN',
                  'DN',
                  'MCRCL1__p__GLMQRCL3'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL3.DN }}
            </div>
          </div>
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_00e">煤气成分密度ROU</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL1.ROU,
                  '煤气成分密度ROU',
                  'ROU',
                  'MCRCL1__p__GLMQRCL1'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL1.ROU }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.ROU,
                  '煤气成分密度ROU',
                  'ROU',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.ROU }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.ROU,
                  '煤气成分密度ROU',
                  'ROU',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.ROU }}
            </div>
          </div>
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_00e">煤气软测量系数K1</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL1.K1,
                  '煤气软测量系数K1',
                  'K1',
                  'MCRCL1__p__GLMQRCL1'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL1.K1 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.K1,
                  '煤气软测量系数K1',
                  'K1',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.K1 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL3.K1,
                  '煤气软测量系数K1',
                  'K1',
                  'MCRCL1__p__GLMQRCL3'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL3.K1 }}
            </div>
          </div>
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_00e">煤气软测量系数K2</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL1.K2,
                  '煤气软测量系数K2',
                  'K2',
                  'MCRCL1__p__GLMQRCL1'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL1.K2 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.K2,
                  '煤气软测量系数K2',
                  'K2',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.K2 }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL3.K2,
                  '煤气软测量系数K2',
                  'K2',
                  'MCRCL1__p__GLMQRCL3'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL3.K2 }}
            </div>
          </div>
          <div class="down_right_line1 flex">
            <div class="right_column1 flex">
              <div class="special_words color_00e">煤气软测量值</div>
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL1.Q,
                  '煤气软测量值',
                  'Q',
                  'MCRCL1__p__GLMQRCL1'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL1.Q }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL2.Q,
                  '煤气软测量值',
                  'Q',
                  'MCRCL1__p__GLMQRCL2'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL2.Q }}
            </div>
            <div
              class="right_column2 color_00"
              @click="
                toIpt(
                  infoList.MCRCL1__p__GLMQRCL3.Q,
                  '煤气软测量值',
                  'Q',
                  'MCRCL1__p__GLMQRCL3'
                )
              "
            >
              {{ infoList.MCRCL1__p__GLMQRCL3.Q }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isFshow = false"
    ></Firstcontrol>
    <Rsf
      v-if="isRshow"
      @sendStatus="isRshow = false"
      :titname="Rsftitname"
      :infoList="infoList"
      :historyname="Rsfname"
      :node="Rsfnode"
    ></Rsf>
    <ParameterYh
      v-if="isComShow"
      :infoList="infoList"
      @sendStatus="isshowfase"
    ></ParameterYh>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    >
    </Manual>
  </div>
</template>
		
		<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historical.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: { inputVal, Historical, Firstcontrol, Rsf, ParameterYh, Manual },
  data: () => {
    return {
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontol";
            break;
          case 2:
            pathname = "rqkfirstcontol";
            break;
          case 3:
            pathname = "RqRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "RqSoftMeasurement", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type, status) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type, status);
      }
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            console.log(1);
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
#RqSoftMeasurement {
  width: 53vw;
  height: 67vh;
  background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5vh 0 0 -26.5vw;
  z-index: 999;
  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
  }
  .zhuangshizb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-top: 2vh;
  }
  .zhuangshizj {
    width: 19vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 1vw;
  }
  .zhuangshiyb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 1vw;
    margin-top: 2vh;
  }
  .icon {
    width: 2vw;
    height: 3vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 13vw;
    margin-top: -0.5vh;
    cursor: pointer;
  }
  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 3vh;
    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }
  .content {
    width: 52vw;
    margin-left: 0.5vw;
    margin-top: 2vh;
    .up {
      height: 33vh;
      border: 1px solid #00e4ff;
      box-sizing: border-box;
      .up_left_line {
        height: 33vh;
        width: 3vw;
        border: 1px solid rgba(0, 228, 255, 0.2);
        box-sizing: border-box;
        font-family: PingFang-SC-Regular;
        font-size: 2vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        .left_line1 {
          margin-top: 11vh;
          margin-left: 1vw;
        }
      }
      .right_line {
        font-family: PingFang-SC-Regular;
        font-size: 2vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.75vh;
        letter-spacing: 0vh;
        .color_2f {
          color: #2fc3e3;
        }
        .color_8a {
          color: #8aeaff;
        }
        .color_00 {
          color: #00ffb4;
		  cursor: pointer;
        }
        .up_right_line1 {
          .right_column1 {
            width: 19vw;
            border: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            .special_words {
              margin-left: 2vw;
              width: 11vw;
            }
            .spsecial_num {
              color: #00ffb4;
			  cursor: pointer;
            }
          }
          .right_column2 {
            width: 10vw;
            border: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            text-align: center;
			cursor: pointer;
          }
        }
      }
    }
    .down {
      height: 19vh;
      margin-top: 2vh;
      border: 1px solid #00e4ff;
      box-sizing: border-box;
      .color_00e {
        color: #00e4ff;
      }
      .color_00 {
        color: #00ffb4;
		cursor: pointer;
      }
      .down_left_line {
        height: 19vh;
        width: 3vw;
        border: 1px solid rgba(0, 228, 255, 0.2);
        box-sizing: border-box;
        font-family: PingFang-SC-Regular;
        font-size: 2vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        .left_line2 {
          margin-top: 1vh;
          margin-left: 1vw;
        }
      }
      .right_line {
        font-family: PingFang-SC-Regular;
        font-size: 2vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.9vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        .down_right_line1 {
          .right_column1 {
            width: 19vw;
            border: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            .special_word1 {
              text-align: center;
              width: 19vw;
            }
            .special_words {
              margin-left: 3vw;
              width: 10vw;
            }
          }
          .right_column2 {
            width: 10vw;
            border: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>